import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import axios from 'axios';
import VueImg from 'v-img';
import VueHorizontal from "vue-horizontal";



Vue.use(VueImg);
Vue.use(VueHorizontal)
Vue.config.productionTip = false;

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: 'https://admin.itsyourdayofficial.com/api/'
    })
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
